<template>
  <div id="capital">
    <div class="headbox">
      <el-form ref="listForm" :inline="true" :model="form">
        <el-form-item class="" label="品牌/车系名称:">
          <el-input v-model="form.brand_name" placeholder="请输入品牌/车系名称" clearable></el-input>
        </el-form-item>
        <el-form-item class="" label="卖家名称:">
          <el-input v-model="form.owner_name" placeholder="请输入卖家名称" clearable></el-input>
        </el-form-item>
        <el-form-item class="" label="买家名称:">
          <el-input v-model="form.buyer_name" placeholder="请输入买家名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="开票时间:">
          <el-date-picker v-model="form.invoice_date" clearable type="daterange" start-placeholder="开始日期" format="yyyy-MM-dd" range-separator="至" value-format="yyyy-MM-dd" end-placeholder="结束日期" :default-time="['12:00:00']">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="所属车行:">
          <el-select v-model="form.car_shop_id" filterable placeholder="请选择" style="margin-right: 10px;" clearable>
              <el-option
                v-for="item in shoplist"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="page = 1;getList()">查询</el-button>
          <el-button type="primary" size="small" @click="exportData">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablebox total">
      <el-row :gutter="20">
        <el-col :span="6">
          纳统数: {{ total }}条
        </el-col>
        <el-col :span="6">
          纳统额: {{ totalAmount }}
        </el-col>
      </el-row>
    </div>
    <div class="tablebox">
      <el-table class="tabels" v-loading="loading" :data="list" style="width:100%" stripe :header-cell-style="{ background: '#fafafa'}">
        <el-table-column prop="order_no" label="记录编号" align="center" width="200">
        </el-table-column>
        <el-table-column label="车型" align="center" width="200">
          <template slot-scope="scope" v-if="scope.row.brandInfo">{{ scope.row.brandInfo.series ? scope.row.brandInfo.series.name : '' }}</template>
          <template v-else>-</template>
        </el-table-column>
        <el-table-column prop="vin" label="VIN号" align="center" width="150">
        </el-table-column>
        <el-table-column label="所属车行" align="center">
          <template slot-scope="scope">
            {{ scope.row.car_shop_info.name }}
          </template>
        </el-table-column>
        <el-table-column label="发票价格" align="center">
          <template slot-scope="scope">
            {{ scope.row.invoice_info.totalAmount }}
          </template>
        </el-table-column>
        <el-table-column label="发票日期" align="center">
          <template slot-scope="scope">
            {{ scope.row.invoice_info.invoiceDate }}
          </template>
        </el-table-column>
        <el-table-column label="买家" align="center">
          <template slot-scope="scope">
            {{ scope.row.invoice_info.purchaserName }}
          </template>
        </el-table-column>
        <el-table-column label="卖家" align="center">
          <template slot-scope="scope">
            {{ scope.row.invoice_info.sellerName }}
          </template>
        </el-table-column>
        <el-table-column label="发票信息" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" :disabled="!scope.row.invoice_info" @click="showInvoice(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pages" v-if="total > 10">
      <el-pagination background :hide-on-single-page="true" @current-change="changepage" :current-page="page" :page-size="10" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>

    <el-dialog
      title="发票信息"
      custom-class="assessmentBox"
      :visible.sync="invoiceVisible"
      width="990px"
      center
    >
      <div class="formBox">
        <el-form :model="invoiceInfo" :disabled="disProfile" label-width="92px">
          <el-form-item label="销售发票:">
            <div class="goodsimage">
              <div class="imgbox">
                <el-image
                  v-if="invoiceInfo.invoice"
                  :src="invoiceInfo.invoice"
                  fit="cover"
                  @click="preview(invoiceInfo.invoice)"
                ></el-image>
                <i
                  v-if="!invoiceInfo.invoice"
                  class="el-icon-plus addicon"
                ></i>
                <div v-if="!invoiceInfo.invoice" class="text">添加图片</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="发票号码:">
            <el-input
              v-model="invoiceInfo.invoiceNumber"
              placeholder="请输入发票号码"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="发票日期:">
            <el-date-picker
              v-model="invoiceInfo.invoiceDate"
              type="date"
              placeholder="选择发票日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="发票金额:">
            <el-input
              v-model="invoiceInfo.totalAmount"
              placeholder="请输入发票金额"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="车架号:">
            <el-input
              v-model="invoiceInfo.vinCode"
              placeholder="请输入车架号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="品牌型号:">
            <el-input
              v-model="invoiceInfo.brandMode"
              placeholder="请输入品牌型号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="买方:">
            <el-input
              v-model="invoiceInfo.purchaserName"
              placeholder="请输入买方信息"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="买方证件号:">
            <el-input
              v-model="invoiceInfo.purchaserCode"
              placeholder="请输入买方证件号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="卖方:">
            <el-input
              v-model="invoiceInfo.sellerName"
              placeholder="请输入卖方信息"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="卖方证件号:">
            <el-input
              v-model="invoiceInfo.sellerCode"
              placeholder="请输入卖方证件号"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <div v-show="false">
      <el-image
        style="width: 100px; height: 100px"
        ref="previewImg"
        :src="phoneList[0]"
        :preview-src-list="phoneList"
      >
      </el-image>
    </div>
  </div>
</template>
        
<script>
import { eltips, dateFormatY } from '@/util/util.js';
import { getCity } from '../region.js';
export default {
  name: 'capital',
  data() {
    return {
      title: '新增',
      shoplist: [],
      form: {
        brand_name: '',
        model_id: '',
        car_shop_id: parseInt(this.$route.query.id) ? parseInt(this.$route.query.id) : '',
        model_id: '',
        invoice_date: [],
        owner_name: '',
        buyer_name: '',
      },
      list: [],
      loading: false,
      invoiceVisible: false,
      invoiceInfo: {
        invoice: "",
        invoiceNumber: "",
        invoiceDate: "",
        totalAmount: "",
        vinCode: "",
        brandMode: "",
        purchaserName: "",
        sellerName: "",
        sellerCode: "",
      },
      disProfile: true,
      phoneList: [],
      page: 1,
      total: 0,
      totalAmount: 0,
    }
  },
  created() {
    this.getList();
    this.getShopList()
  },
  watch: {
    
  },
  methods: {
    getList() {
      this.loading = true;
      let data = { ...this.form, ...{ page: this.page } };
      this.axios.get('/api/car/admin/finance/invoice/records', { params: data }).then(res => {
        if (res.code == 200) {
          this.loading = false;
          this.list = [...res.data.data];
          this.total = res.data.total;
          this.totalAmount = res.data.totalAmount
        }
      });
    },
    getShopList() {
      this.axios.get('/api/car/admin/car_shops?is_page=0').then(res => {
        if (res.code == 200) {
          this.shoplist = res.data;
        }
      })
    },
    exportData(){
      let data = this.form;
      data.export = 1
      this.axios.get('/api/car/admin/finance/invoice/records',{
					params: data,
					responseType: "blob",
				}).then(res => {
          const blob = new Blob([res]);
          if (window.navigator.msSaveOrOpenBlob) {
            // 兼容ie
            window.navigator.msSaveBlob(blob, "发票明细.xls");
          } else {
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob); //创建下载的链接
            console.log(href)
            downloadElement.href = href;
            downloadElement.download = "发票明细.xls"; // 下载后文件名
            document.body.appendChild(downloadElement);
            // 此写法兼容火狐
            const evt = document.createEvent("MouseEvents");
            evt.initEvent("click", false, false);
            downloadElement.dispatchEvent(evt);
            document.body.removeChild(downloadElement);
          }
      })
    },
    showInvoice(row) {
      this.invoiceVisible = true;
      this.invoiceInfo = { ...row.invoice_info };
    },
    preview(url) {
      this.phoneList = [url];
      this.$refs.previewImg.showViewer = true;
    },
    //页码切换事件
    changepage(e) {
      this.page = e;
      this.getList();
    },
  }
}
</script>
        
<style scoped lang="less">
/deep/.el-input__prefix {
  .el-input__icon {
    margin-top: -4px !important;
  }
  .el-icon-date {
    margin-top: -4px !important;
  }
}

/deep/.el-form {
  .el-form-item {
    .el-select,
    .el-date-editor,
    .el-cascader {
      width: 100%;
      line-height: normal;
    }

    .el-tabs--border-card {
      box-shadow: none;
    }
  }
}
#capital {
  min-height: calc(100vh - 110px);

  .headbox {
    display: flex;
    padding: 20px;
    border-radius: 2px;
    align-items: center;
    background: #ffffff;
    justify-content: space-between;

    /deep/.el-form {
      .el-form-item {
        margin-bottom: 20px;
        &.w120 {
          .el-form-item__content {
            width: 120px;
          }
        }
        &.w150 {
          .el-form-item__content {
            width: 150px;
          }
        }

        .el-date-editor {
          width: 240px;

          .el-range-separator {
            width: 18px;
          }
        }
      }
    }
  }

  .tablebox {
    margin-top: 10px;
    background: #ffffff;
  }

  .total{
    padding: 10px;
  }

  /deep/.el-dialog__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .el-dialog {
      margin: 0 !important;

      .el-dialog__body {
        height: 500px;
        padding: 10px;
        overflow: auto;
      }

      &.assessmentBox {
        .el-dialog__body {
          height: auto;
        }
      }
    }
  }

  .addCapital {
    .titlesty {
      font-weight: bold;
      padding: 9px 20px;
      font-size: 16px;
      background: #f4f6f8;
      margin-top: 20px;

      &:nth-child(1) {
        margin-top: 0px;
      }
    }

    .frombox {
      width: 100% !important;
      margin-top: 20px;

      .el-form {
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
          flex: 0 50%;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0px;
          }

          .el-select,
          .el-date-editor,
          .el-cascader {
            width: 100%;
            line-height: normal;
          }

          .el-tabs--border-card {
            box-shadow: none;
          }
        }
      }
    }
  }

  .formBox {
    .el-form {
      display: flex;
      flex-wrap: wrap;
      .el-form-item {
        flex: 0 50%;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .pages {
    text-align: center;
    background: #ffffff;
    padding: 20px 0;
    border-top: 1px solid #ededed;
  }
  .goodsimage {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .imgbox {
      border: 1px dashed #e9edef;
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 20px;
      position: relative;

      .img {
        width: 100px;
        height: 100px;
      }

      .addicon {
        font-size: 28px;
        color: #1989fa;
      }

      .delicon {
        font-size: 18px;
        position: absolute;
        right: -8px;
        top: -8px;
      }

      .text {
        font-size: 12px;
        color: #b8b9bd;
        line-height: 16px;
      }
    }
  }
}
</style>